var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user
    ? _c(
        "div",
        {
          staticClass:
            "c_psersonal_center_vip_and_consumption_vip_tidy bbox un_sel",
        },
        [
          _c(
            "div",
            {
              staticClass: "user_group d-flex align-center bbox",
              class: { vip: _vm.user.userVip },
            },
            [
              _c("img", {
                staticClass: "avatar",
                attrs: {
                  src: `${_vm.$avatar_url}${_vm.user.thumbnail}`,
                  alt: "",
                },
              }),
              _c("div", { staticClass: "info_group" }, [
                _c(
                  "div",
                  { staticClass: "name_group d-flex align-center pb-10" },
                  [
                    _c("div", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.user.userName)),
                    ]),
                    !_vm.user.userVip
                      ? _c("div", { staticClass: "tag ml-10" }, [
                          _vm._v("普通用户"),
                        ])
                      : _vm._e(),
                    _vm.user.userVip
                      ? _c("img", {
                          staticClass: "vip",
                          attrs: {
                            src: require("@/assets/images/personal_center/vip_mark.png"),
                            alt: "",
                          },
                        })
                      : _vm._e(),
                  ]
                ),
                _c("div", { staticClass: "hint pt-10" }, [
                  _c("span", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.user.userVip
                            ? `您已是VIP用户，有效期至${_vm
                                .$moment(_vm.user.vipEndDate)
                                .format("YYYY-MM-DD")}，续费后有效期将顺延`
                            : "成为VIP用户，解锁更多功能"
                        ) +
                        "\n        "
                    ),
                  ]),
                  _vm.user.userVip
                    ? _c(
                        "span",
                        {
                          staticClass: "btn cp",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.checkOpenRecord.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("\n          开通记录 >\n        ")]
                      )
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
          _c("div", { staticClass: "title" }, [_vm._v("专属VIP用户权益")]),
          _c(
            "div",
            {
              staticClass:
                "entitlemen_table d-flex align-center flex-wrap un_sel",
            },
            [
              _vm._l(_vm.entitlemen_table, function (item, index) {
                return [
                  _c(
                    "div",
                    { key: index, staticClass: "item d-flex align-center" },
                    [
                      _c("img", {
                        staticClass: "img mr-10",
                        attrs: {
                          src: require(`@/assets/images/personal_center/vip/vip${item.pic}.png`),
                          alt: "",
                        },
                      }),
                      _c("div", { staticClass: "text" }, [
                        _vm._v(_vm._s(item.text)),
                      ]),
                    ]
                  ),
                ]
              }),
            ],
            2
          ),
          _c("div", { staticClass: "title" }, [_vm._v("支付购买")]),
          _c(
            "div",
            {
              staticClass: "buy_group bbox d-flex flex-column align-center",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.becomeMember()
                },
              },
            },
            [
              _c("div", { staticClass: "time" }, [_vm._v("1年")]),
              _vm._m(0),
              _c("div", { staticClass: "button" }, [_vm._v("点击开通")]),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "price_group" }, [
      _c("span", { staticClass: "price" }, [_vm._v("800.00")]),
      _c("span", { staticClass: "unit" }, [_vm._v("元")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }