<template>
  <div
    class="views_personal_center_vip_and_consumption_page bbox d-flex flex-column"
  >
    <tab-bar
      class="flex-shrink-0"
      :type="type"
      @handleChange="changeType"
    ></tab-bar>
    <div class="main">
      <vip-tidy
        v-show="type==='vip'"
      ></vip-tidy>
      <vip
        v-if="0"
      ></vip>
    </div>
  </div>
</template>

<script>
import TabBar from '@/components/PersonalCenter/VipAndConsumption/TabBar.vue';
import Vip from '@/components/PersonalCenter/VipAndConsumption/Vip/index.vue';
import VipTidy from '@/components/PersonalCenter/VipAndConsumption/Vip/Tidy.vue'
export default {
  components: {
    TabBar,
    Vip,
    VipTidy,
  },
  data(){
    return {
      type: 'vip',
    }
  },
  methods: {
    changeType(type){
      this.type = type;
    }
  }
}
</script>

<style lang="scss" scoped>
.views_personal_center_vip_and_consumption_page{
  width: 100%;
  height: 100%;
  background-color: #fff;
  .main{
    flex: 1;
    width: 100%;
    overflow: auto;
  }
}
</style>
