var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "views_personal_center_vip_and_consumption_page bbox d-flex flex-column",
    },
    [
      _c("tab-bar", {
        staticClass: "flex-shrink-0",
        attrs: { type: _vm.type },
        on: { handleChange: _vm.changeType },
      }),
      _c(
        "div",
        { staticClass: "main" },
        [
          _c("vip-tidy", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.type === "vip",
                expression: "type==='vip'",
              },
            ],
          }),
          0 ? _c("vip") : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }