<!--
 * @Author: hugo 1634765756@qq.com
 * @Date: 2022-05-18 14:40:10
 * @LastEditors: hugo 1634765756@qq.com
 * @LastEditTime: 2022-05-19 14:58:49
 * @FilePath: /miaohang/src/components/PersonalCenter/VipAndConsumption/Vip/Tidy.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="c_psersonal_center_vip_and_consumption_vip_tidy bbox un_sel"
    v-if="user"
  >
    <div class="user_group d-flex align-center bbox"
      :class="{vip: user.userVip}"
    >
      <img 
        :src="`${$avatar_url}${user.thumbnail}`" alt="" class="avatar"
      >
      <div class="info_group">
        <div class="name_group d-flex align-center pb-10">
          <div class="name">{{user.userName}}</div>
          <div class="tag ml-10"
            v-if="!user.userVip"
          >普通用户</div>
          <img 
            src="~@/assets/images/personal_center/vip_mark.png" alt="" class="vip"
            v-if="user.userVip"
          >
        </div>
        <div class="hint pt-10">
          <span>
            {{ user.userVip? `您已是VIP用户，有效期至${$moment(user.vipEndDate).format('YYYY-MM-DD')}，续费后有效期将顺延` : '成为VIP用户，解锁更多功能' }}
          </span>
          <span
            v-if="user.userVip"
            class="btn cp"
            @click.stop="checkOpenRecord"
          >
            开通记录 &gt;
          </span>
        </div>
      </div>
    </div>
    <div class="title">专属VIP用户权益</div>
    <div class="entitlemen_table d-flex align-center flex-wrap un_sel">

      <template
        v-for="(item,index) in entitlemen_table"
      >
        <div class="item d-flex align-center"
          :key="index"
        >
          <img 
            :src="require(`@/assets/images/personal_center/vip/vip${item.pic}.png`)" alt="" class="img mr-10"
          >
          <div class="text">{{item.text}}</div>
        </div>

      </template>
     
    </div>
    <div class="title">支付购买</div>
    <div class="buy_group bbox d-flex flex-column align-center"
      @click.stop="becomeMember()"
    >
      <div class="time">1年</div>
      <div class="price_group">
        <span class="price">800.00</span>
        <span class="unit">元</span>
      </div>
      <div class="button">点击开通</div>
    </div>
  </div>
</template>

<script>
import tools from '@/api/tools.js'
export default {
  data() {
    return{
      user: undefined,
      entitlemen_table: [
        {text: '查看联系人能力画像的具体数值', pic: 1},
        {text: '收藏“模板商城”中的任务', pic: 2},
        {text: '使用“智慧桌”控件', pic: 3},
        {text: '创建任务自定义标签', pic: 4},
        {text: '在“我收藏的”中创建文件夹', pic: 5},
      ]
    }
  },
  computed: {
    user_data_update(){
      return this.$store.state.user_data_update;
    },
  },
  watch: {
    user_data_update: function(val){
      if(val){
        const user = this.$tools.getCookie('user')
        this.$set(this, 'user', user)
      }
    }
  },
  mounted(){
    const user = tools.getCookie('user')
    this.$set(this, 'user', user)
    // this.$become_member({
    //   renew: true,
    // });
  },
  methods: {
    checkOpenRecord() {
      this.$openMemberOpenRecord()
    },
    becomeMember(){
      const {user} = this
      const {userVip} = user
      this.$become_member({
        renew: Boolean(userVip)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.c_psersonal_center_vip_and_consumption_vip_tidy{
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  .user_group{
    width: 100%;
    padding: 40px;
    background: {
      color:  #F4F4F4;
      image: url(~@/assets/images/personal_center/vip/bg_not_vip.png);
      repeat: no-repeat;
      position: 80% center;
    }
    border-radius: 4px;
    margin-bottom: 30px;
    &.vip{
      background: {
        color: #FFF1DC;
        image: url(~@/assets/images/personal_center/vip/bg_vip.png);
      }
    }
    .avatar{
      width: 80px;
      height: 80px;
      display: block;
      margin-right: 30px;
    }
    .name_group{
      .name{
        font-weight: bold;
        font-size: 22px;
        color: #333;
      }
      .tag{
        padding: 0 10px;
        height: 18px;
        background: #D8D8D8;
        border-radius: 2px;
        color: #666;
        font-size: 12px;
        line-height: 18px;
      }
    }
    .hint{
      font-size: 16px;
      color: #555555;
      line-height: 21px;
      span{
        display: inline-block;
      }
      .btn{
        margin-left: 10px;
        padding-left: 10px;
        border-left: 2px solid rgba(85, 85, 85, 0.3);
        color: #884706;
      }
    }
  }
  .title{
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    line-height: 24px;
    margin: {
      bottom: 30px;
    }
  }
  .entitlemen_table{
    width: 100%;
    .item{
      // flex: 1;
      width: 32%;
      margin-right: 1%;
      margin-bottom: 30px;
      color: #555;
      font-size: 16px;
    }
  }
  .buy_group{
    width: 250px;
    border-radius: 10px;
    border: 1px solid #884706;
    .time{
      padding-top: 20px;
      font-size: 18px;
      font-weight: bold;
      color: #884706;
      line-height: 24px;
    }
    .price_group{
      padding-bottom: 20px;
      span{
        display: inline-block;
        vertical-align: text-bottom;
        font-size: 28px;
        font-weight: bold;
        color: #F3580C;
      }
      .unit{
        font-size: 14px;
        line-height: 30px;
      }
    }
    .button{
      width: 100%;
      line-height: 40px;
      background: #F4F4F4 linear-gradient(135deg, #605853 0%, #2F2E32 100%);
      border-radius: 0px 0px 8px 8px;
      font-size: 14px;
      color: #FFDC9D;
      text-align: center;
    }
  }
}
</style>