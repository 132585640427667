var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_personal_center_vip_and_consumption_tab_bar_page bbox d-flex",
    },
    [
      0
        ? _c(
            "div",
            {
              staticClass: "button cp",
              class: { active: _vm.type === "consumption" },
              on: {
                click: function ($event) {
                  return _vm.changeType("consumption")
                },
              },
            },
            [_vm._v("消费记录")]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "button cp",
          class: { active: _vm.type === "vip" },
          on: {
            click: function ($event) {
              return _vm.changeType("vip")
            },
          },
        },
        [_vm._v("会员权益")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }