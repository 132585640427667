<template>
  <div
    class="components_personal_center_vip_and_consumption_tab_bar_page bbox d-flex"
  >
    <div class="button cp"
      v-if="0"
      @click="changeType('consumption')"
      :class="{active: type === 'consumption'}"
    >消费记录</div>
    <div class="button cp"
      @click="changeType('vip')"
      :class="{active: type === 'vip'}"
    >会员权益</div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: '',
    }
  },
  methods: {
    changeType(type){
      this.$emit('handleChange', type);
    }
  }
}
</script>

<style lang="scss" scoped>
.components_personal_center_vip_and_consumption_tab_bar_page{
  width: 100%;
  height: 60px;
  border-bottom: 2px solid #eee;
  .button{
    @include transition;
    color: #333;
    font-size: 14px;
    line-height:58px;
    position: relative;
    margin-left: 30px;
    &.active{
      color: $main_blue;
      &::after{
        position: absolute;
        content: '';
        display: block;
        width: 100%;
        height: 4px;
        background-color: $main_blue;
        bottom: 0;
        left: 0;
      }
    }
  }
}
</style>